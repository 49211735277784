import { extendTheme } from '@chakra-ui/react';

import schemes from './colorSchemes';

const customTheme: Record<string, unknown> = {
  fonts: {
    heading: 'var(--font-poppins)',
    body: 'var(--font-poppins)',
  },
  textStyles: {
    uppercase: {
      textTransform: 'uppercase',
    },
  },
  ...schemes.medsimples,
};

export const theme = extendTheme(customTheme);
