export interface PageRoute {
  name: string;
  permissions: string[];
  features?: string[];
}

export const PageRoutes = {
  '/doctors': {
    name: 'Médicos',
    permissions: ['admin.listProfessionals'],
  } as PageRoute,
  '/search': {
    name: 'Consulta',
    permissions: ['admin.getBusinessPartnerBukrs'],
  } as PageRoute,
  '/search/business-partner': {
    name: 'Business Partner',
    permissions: ['admin.getBusinessPartnerBukrs'],
  } as PageRoute,
  '/contract': {
    name: 'Contratos',
    permissions: ['admin.listProfessionals', 'admin.listContracts'],
    features: ['CONTRACT_MODULE'],
  } as PageRoute,
  '/contract/reviewer': {
    name: 'Aprovadores',
    permissions: [
      'admin.listProfessionals',
      'admin.listContracts',
      'admin.listContractReviewer',
    ],
    features: ['CONTRACT_MODULE', 'CONTRACT_REVIEWER'],
  } as PageRoute,
};
