'use client';

import { tokens } from '@/styles/tokens';
import {
  Box,
  Drawer,
  DrawerContent,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';
import { Poppins } from 'next/font/google';
import { usePathname } from 'next/navigation';
import type { ReactNode } from 'react';
import Breadcrumb from './components/layout/breadcrumb';
import Header from './components/layout/header';
import { Sidebar } from './components/layout/sidebar';
import { UserInfo } from './components/layout/userinfo';
import { Providers } from './providers';

const poppins = Poppins({
  weight: ['300', '400', '500', '600', '700'],
  subsets: ['latin'],
});

interface props {
  children: ReactNode;
}

export default function Layout({ children }: props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const pathname = usePathname();

  return (
    <html lang='pt-BR'>
      <style jsx global>
        {`
          :root {
            --font-poppins: ${poppins.style.fontFamily};
          }
        `}
      </style>
      <head>
        <title>Doctor Backoffice</title>
        <meta name='description' content='Gerenciamento de cadastro médico' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <link rel='icon' href='/favicon.ico' />
      </head>
      <body>
        <Providers>
          {pathname === '/login' ? (
            <Flex
              h='100vh'
              w='100vw'
              justifyContent='start'
              alignItems='center'
              flexWrap='nowrap'
            >
              {children}
            </Flex>
          ) : (
            <Box minH='100vh' minW='100vw' bg='gray.100'>
              <Drawer
                autoFocus={false}
                isOpen={isOpen}
                placement='left'
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size='xs'
              >
                <DrawerContent>
                  <Sidebar onClose={onClose} />
                </DrawerContent>
              </Drawer>
              <UserInfo onOpen={onOpen} />
              <Box
                padding={10}
                background={tokens.background.ice.color}
                minHeight='calc(100vh - 80px)'
              >
                <Header />
                <Breadcrumb />
                {children}
              </Box>
            </Box>
          )}
        </Providers>
      </body>
    </html>
  );
}
